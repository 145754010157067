import { i18next } from '@operato/i18n'

export { generatePasswordPatternRegExp } from '@operato/utils'

export function generatePasswordPatternHelp({
  lowerCase = true,
  upperCase = true,
  digit = true,
  specialCharacter = true,
  allowRepeat = false,
  useTightPattern = true,
  useLoosePattern = false,
  tightCharacterLength = 8,
  looseCharacterLength = 15
} = {}) {
  var descriptions: string[] = []

  if (useLoosePattern) {
    descriptions.push(`more than ${looseCharacterLength} characters`)
  }

  if (useTightPattern) {
    !useLoosePattern &&
      descriptions.push(
        i18next.t('text.pattern_minimum_charaters', {
          length: tightCharacterLength
        })
      )
    lowerCase && descriptions.push(i18next.t('text.pattern_atleast_1_lowercase'))
    upperCase && descriptions.push(i18next.t('text.pattern_atleast_1_uppercase'))
    digit && descriptions.push(i18next.t('text.pattern_atleast_1_digit'))
    specialCharacter && descriptions.push(i18next.t('text.pattern_atleast_1_special'))
    !allowRepeat && descriptions.push(i18next.t('text.pattern_not_allowed'))
  }

  return descriptions.join(', ')
}
