/**
 * Generates a regular expression pattern for validating passwords based on specified criteria.
 *
 * @param {object} options - An object containing options for generating the pattern.
 * @param {boolean} options.lowerCase - Indicates whether the password should contain at least one lowercase character.
 * @param {boolean} options.upperCase - Indicates whether the password should contain at least one uppercase character.
 * @param {boolean} options.digit - Indicates whether the password should contain at least one digit.
 * @param {boolean} options.specialCharacter - Indicates whether the password should contain at least one special character.
 * @param {boolean} options.allowRepeat - Indicates whether repeated characters are allowed in the password.
 * @param {boolean} options.useTightPattern - Indicates whether to use a tight pattern for password length.
 * @param {boolean} options.useLoosePattern - Indicates whether to use a loose pattern for password length.
 * @param {number} options.tightCharacterLength - The minimum length of the password for the tight pattern.
 * @param {number} options.looseCharacterLength - The minimum length of the password for the loose pattern.
 * @returns {RegExp} - A regular expression pattern for validating passwords based on the specified criteria.
 */
export function generatePasswordPatternRegExp({
  lowerCase = true,
  upperCase = true,
  digit = true,
  specialCharacter = true,
  allowRepeat = false,
  useTightPattern = true,
  useLoosePattern = false,
  tightCharacterLength = 8,
  looseCharacterLength = 15
}: {
  lowerCase?: boolean
  upperCase?: boolean
  digit?: boolean
  specialCharacter?: boolean
  allowRepeat?: boolean
  useTightPattern?: boolean
  useLoosePattern?: boolean
  tightCharacterLength?: Number
  looseCharacterLength?: Number
} = {}) {
  var tightChecklist = useTightPattern
    ? [
        lowerCase ? '(?=.*[a-z])' : '', // has at least one lower case character
        upperCase ? '(?=.*[A-Z])' : '', // has at least one upper case character
        digit ? '(?=.*\\d)' : '', // has at least one digit
        specialCharacter ? '(?=.*[!@#$%^&*()])' : '', // has at least one special character
        !allowRepeat ? '(?!.*(.)\\1(?=\\1{1,}))' : '', // has not an repeated character more than twice
        `.{${tightCharacterLength},}` // has a length of 8 and more
      ]
    : []

  var looseChecklist = useLoosePattern
    ? [
        `.{${looseCharacterLength},}` // has a length of 15 and more
      ]
    : []

  var checkList = [
    '^', // from start
    ...tightChecklist,
    tightChecklist.length && looseChecklist.length ? '|' : '',
    ...looseChecklist,
    '$' //to the end"
  ]

  return new RegExp(checkList.join(''))
}
